import React from "react"
import {graphql, Link} from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout"

import Seo from "../components/seo"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBasketballBall} from "@fortawesome/free-solid-svg-icons";
import {faClock, faFolderOpen} from "@fortawesome/free-regular-svg-icons";

const pageIndex = ({ data }) => (
   <Layout>
       <Seo
           pagetitle={`ホーム`}
       />
        <section className="hero">
            <StaticImage src="../images/top_bg--basketball.jpg" alt="ZEROSTEP TOP" style={{ height: "100%" }} />
            <div className="catch">
                <h1 className="hero-logo">
                    <StaticImage src="../images/logo-01.png" alt="ZEROSTEP KUMAMOTO BASKETBALL" />
                </h1>
                <p>熊本のバスケットシーンを応援する情報サイト</p>
            </div>
            <div className="wave">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 229.5" fill="#fff">
                    <path d="M1369,6.3C1222.5-12.2,1189.5,8,919.2,96.6C665,179.8,160,141.7-2,53.1v150l1371-14.2V6.3z" opacity=".53" />
                    <path d="M1369 229.5V55.8c-9.5-2.4-19.2-4.4-28.9-5.8-196.9-29.9-203.4-15.8-503.9 82.6-219.8 72-627.6 53.2-838.2-10.5v107.4h1371z" />
                </svg>
            </div>
        </section>

       <section>
           <div className="container">
               <h2 className="sr-only">最近の記事</h2>

               <div className="posts">

                   {data.allContentfulBlogPost.edges.map(({ node }) => (
                       <article className="post post-item post-1_2" key={node.id}>
                           <Link to={`/post/${node.slug}`}>
                               <figure>
                                   <GatsbyImage
                                       image={node.eyecatch.gatsbyImageData}
                                       alt={node.eyecatch.description}
                                       style={{height:"100%"}} />
                               </figure>
                           </Link>
                           <aside className="info post-item_info">
                               <time dateTime={ node.publishDate }>
                                   <FontAwesomeIcon icon={faClock} />
                                   { node.publishDateJP }
                               </time>

                               <div className="cat">
                                   <FontAwesomeIcon icon={faFolderOpen} />
                                   <ul>
                                       {node.category.map(cat => (
                                           <li className={cat.categorySlug} key={cat.id}>
                                               <Link to={`/cat/${cat.categorySlug}/`}>{cat.category}</Link>
                                           </li>
                                       ))}
                                   </ul>
                               </div>
                           </aside>
                           <Link to={`/post/${node.slug}`}>
                               <h3>{ node.title }</h3>
                           </Link>
                       </article>
                   ))}
               </div>
           </div>
       </section>

       <div className="container">
           <hr/>
       </div>

       <section className="u-pt-3">
           <div className="container">
               <h2>最近の試合結果</h2>
               <div className="posts">
                   {data.allContentfulGameResult.edges.map(({ node }) => (
                       <article className="post post-1_3" key={node.id}>
                           <Link to={`/result/${node.slug}`}>
                               <figure>
                                   <GatsbyImage
                                       image={node.eyecatch.gatsbyImageData}
                                       alt={node.eyecatch.description}
                                       style={{height:"100%"}} />
                               </figure>
                           </Link>
                               <aside className="info post-item_info">
                                   <time dateTime={ node.publishDate }>
                                       <FontAwesomeIcon icon={faClock} />
                                       { node.publishDateJP }
                                   </time>

                                   <div className="cat">
                                       <FontAwesomeIcon icon={faBasketballBall} />
                                       <ul>
                                           {node.category.map(cat => (
                                               <li className={cat.categorySlug} key={cat.id}>
                                                   <Link to={`/gamecat/${cat.categorySlug}/`}>
                                                   {cat.category}
                                                   </Link>
                                               </li>
                                           ))}
                                       </ul>
                                   </div>
                               </aside>
                           <Link to={`/result/${node.slug}`}>
                               <h3>{ node.title }</h3>
                           </Link>
                       </article>
                   ))}
               </div>
           </div>
       </section>

       <section className="photo">
           <h2 className="sr-only">Photo</h2>
           <StaticImage src="../images/bottom_bg--basketball.jpg" alt="バスケットボール" style={{width:"100%"}} />
       </section>

   </Layout>
)
export default pageIndex;

export const query = graphql`
    query {
        allContentfulBlogPost(
            sort: {order: DESC, fields: publishDate}
            skip: 0
            limit: 4
        ) {
            edges {
                node {
                    title
                    publishDateJP : publishDate(formatString: "YYYY年MM月DD日")
                    publishDate   
                    category {
                      category
                      categorySlug
                      id
                    }
                    id
                    slug
                    eyecatch{
                      description
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED
                        width: 573
                        layout: FULL_WIDTH
                      )
                      file {
                        details {
                          image {
                            height
                            width
                          }
                        }
                        url
                      }
                    }
                }
            }
        } 
        allContentfulGameResult(
            sort: {order: DESC, fields: publishDate}
            skip: 0
            limit: 3
        ) {
            edges {
                node {
                    id
                    title
                    publishDateJP : publishDate(formatString: "YYYY年MM月DD日")
                    publishDate   
                    category {
                      category
                      categorySlug
                      id
                    }
                    id
                    slug
                    eyecatch{
                      description
                      gatsbyImageData(
                        formats: [AUTO, WEBP, AVIF]
                        placeholder: BLURRED
                        width: 500
                        layout: FULL_WIDTH
                      )
                      file {
                        details {
                          image {
                            height
                            width
                          }
                        }
                        url
                      }
                    }
                }
            }
        }
    }
`